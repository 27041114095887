<template>
  <v-list class="row black lighten-2 no-gutters pa-0 d-flex">
    <template v-for="item in menuBar">
      <v-list-item
        v-if="item.show"
        :key="item.title"
        :ripple="false"
        v-bind="item.route"
        class="menu justify-center col"
      >
        <v-list-item-action class="text-center">
          <img
            :src="require(`~/assets/img/icons/${item.icon}`)"
            :alt="item.name"
            class="menu-icon"
          >
          <div class="mt-2">
            <span>{{ item.title }}</span>
          </div>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-dialog
      v-model="dialog"
      fullscreen
    >
      <v-card color="black lighten-1">
        <v-card-title class="black lighten-3">
          <v-col cols="4" offset="4" class="text-center headline">
            Meer
          </v-col>
          <v-col cols="4" class="text-right pl-12">
            <v-btn text @click="dialog = false">
              <span class="primary--text caption text-capitalize">Sluiten</span>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-list class="transparent">
            <draggable :list="menuOrder" handle=".grip-handle" @start="drag=true" @end="drag=true">
              <template
                v-for="item in menuOrder"
              >
                <v-list-item
                  v-if="item.show"
                  :key="item.title"
                  v-bind="item.route"
                  class="black lighten-2 px-4 py-2 mt-1"
                  rounded
                  @click="dialog = false"
                >
                  <v-list-item-title class="caption">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <font-awesome-icon
                      :icon="['fas', 'grip-vertical']"
                      class="white--text opacity-20 font-weight-bold ml-2 grip-handle"
                      size="lg"
                    />
                  </v-list-item-action>
                </v-list-item>
              </template>
            </draggable>
          </v-list>
        </v-card-text>
      </v-card>
      <template v-slot:activator="{ on }">
        <v-list-item :ripple="false" class="menu justify-center col" v-on="on">
          <v-list-item-action class="text-center">
            <font-awesome-icon :icon="['fal', 'ellipsis-h']" size="2x"/>
            <div class="mt-2">
              <span>Meer</span>
            </div>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-dialog>
  </v-list>
</template>

<script>
import draggable from 'vuedraggable';
import {mapState} from 'vuex';
import {debounce} from '~/assets/helpers';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      activeBtn: 1,
      showNav: true,
      drawer: true,
      dialog: false,
      menuOrder: [],
    };
  },
  computed: {
    menuBar() {
      const menu = [];
      const max = 4;

      if (this.menuOrder.length) {
        this.menuOrder.forEach((itemIndex, index) => {
          if (index < max) {
            menu.push(itemIndex);
          }
        });
      } else {
        for (let i = 0; i < max; i++) {
          menu.push(i);
        }
      }

      return menu;
    },
    ...mapState(['menu']),
  },
  watch: {
    menuOrder: {
      handler: debounce(async function (newVal) {
        newVal = newVal.map(item => item.title.toLowerCase());
        await this.$axios.post('/settings/menu', {order: newVal});
      }, 2000),
      deep: true,
    },
  },
  mounted() {
    this.menuOrder = this.menu.filter((menuItem) => {
      if (this.user.menu_order.includes(menuItem.title.toLowerCase())) {
        return menuItem;
      }
    });
  },
};
</script>

<style lang="sass" scoped>

.theme--dark
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):not(:hover)
    color: rgba(142, 142, 144, 1) !important

  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    color: var(--v-primary-base) !important

.v-list-item
  cursor: pointer

::v-deep .v-list-item.menu
  flex: 1 1 0
  border-bottom: 2px solid transparent
  padding: 0 10px
  width: auto
  font-size: 11px

  &.v-list-item--active, &:hover
    border-bottom: 2px solid var(--v-primary-base)
    color: var(--v-primary-base) !important

.v-list-item__action--stack
  align-items: center !important

.v-list-item--link:before
  background-color: rgba(216, 216, 216, 0.3)

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child
  margin-right: 0 !important

.menu-icon
  position: relative
  height: 22px
  width: auto

::v-deep .v-dialog--fullscreen
  height: calc(100% - 70px)

</style>
