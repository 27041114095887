const middleware = {}

middleware['activeModules'] = require('../middleware/activeModules.js')
middleware['activeModules'] = middleware['activeModules'].default || middleware['activeModules']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['hasAccess'] = require('../middleware/hasAccess.js')
middleware['hasAccess'] = middleware['hasAccess'].default || middleware['hasAccess']

middleware['pages'] = require('../middleware/pages.js')
middleware['pages'] = middleware['pages'].default || middleware['pages']

export default middleware
