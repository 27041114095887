import Vue from 'vue';
import {mapState} from 'vuex';

const User = {
  install(Vue) {
    Vue.mixin({
      computed: mapState({
        user: state => state.auth.user,
      }),
      // mounted() {
      //   if (this.user && this.$gtm) {
      //     this.$gtm.pushEvent({event: 'userId', userId: this.user.uuid});
      //   }
      // },
    });
  },
};

Vue.use(User);
