<template>
  <div>
    <add-attachment
      v-if="attachment === null"
      title="Selecteer een bestand"
      :accepted-file-types="acceptedFileTypes"
      @change:file-upload="uploadedFile"
    />
    <attachment
      v-else
      :file="attachment"
      :sub-title="subTitle"
      @clicked:delete="deletedFile"
    />
  </div>
</template>

<script>
import AddAttachment from '@/components/forms/AddAttachment';
import Attachment from '@/components/forms/Attachment';
import EventBus from '../../plugins/eventbus';

export default {
  components: {
    AddAttachment,
    Attachment,
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attachment: null,
    };
  },
  mounted() {
    if (this.dialog) {
      EventBus.$on('confirm-dialog-close', () => {
        this.attachment = null;
      });
    }
  },
  methods: {
    uploadedFile(file) {
      this.attachment = file;
      this.$emit('action:submit', file);
    },
    deletedFile(id) {
      this.attachment = null;
    },
  },
};
</script>
