<template>
  <form id="form" ref="form" method="post" class="mt-4" @submit.prevent="submit">
    <div v-show="!otp">
      <h3>Inloggen</h3>
      <div class="mb-6 opacity-50">Vul hier je gegevens in.</div>

      <text-field
        v-model="form.email"
        :error-messages="errors.email"
        type="email"
        label="E-mailadres"
        autocomplete="email"
      />
      <text-field
        v-model="form.password"
        :error-messages="errors.password"
        :append-icon="passwordVisible ? '$vuetify.icons.visibility' : '$vuetify.icons.visibility_off'"
        :type="passwordVisible ? 'text' : 'password'"
        label="Wachtwoord"
        autocomplete="current-password"
        @click:append="passwordVisible = !passwordVisible"
      />
    </div>
    <div v-if="otp">
      <h3>Tweestapsverificatie</h3>
      <div class="mb-6 opacity-50">
        Voer je tweestapsverificatie code in die door je authenticator app is aangemaakt.
      </div>

      <text-field
        v-model="form['2fa_code']"
        :error-messages="errors['2fa_code']"
        label="Verificatiecode"
      />
    </div>
    <v-btn
      :loading="loading"
      type="submit"
      color="accent"
      depressed
      block
      large
      class="mt-0 mb-2 caption font-weight-bold border-radius-4"
    >
      Inloggen
    </v-btn>
  </form>
</template>

<script>
import formMixin from '~/plugins/mixins/form';
import TextField from '~/components/fields/TextField';

export default {
  components: {TextField},
  mixins: [formMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: false,
      },
      otp: false,
      passwordVisible: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        this.$store.commit('notify/hide');
        await this.$auth.loginWith('local', {
          data: this.form,
        });
      } catch (e) {
        if (e.response.status === 422) {
          this.focusFirstError(this);
        } else if (e.response.status === 423) {
          this.$store.commit('notify/info', 'Dit account is niet meer actief.');
        } else if (e.response.status === 428) {
          this.$store.commit('notify/hide');
          this.otp = true;
        } else if (e.response.status === 412) {
          this.$store.commit('notify/info', 'Verificatiecode incorrect, probeer opnieuw.');
          this.errors['2fa_code'] = 'Verificatiecode incorrect';
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>

::v-deep .svg-inline--fa
  width: 0.8em
  margin-right: 10px
  opacity: 0.5

</style>
