import Vue from 'vue';
import DaySpan from 'dayspan-vuetify-2';
import 'dayspan-vuetify-2/src/styles/app.scss';
import nlDS from 'dayspan-vuetify-2/src/locales/nl';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import vuetify from 'dayspan-vuetify-2/src/plugins/vuetify';

const isLight = function (color) {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b),
  );

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5;
};

nlDS.defaults.dsWeekDayHeader.formats.weekday = 'dd';

Vue.use(DaySpan, {
  // These data and methods override the ones defined in node_modules/dayspan-vuetify-2/src/components.js
  data: {
    inactiveBlendAmount: 0.7,
    placeholderBlendAmount: 0.5,
    features: {
      move: false,
      moveDuplicate: false,
      moveInstance: false,
      moveAll: false,
    },
  },
  methods: {
    getDefaultEventColor() {
      return process.env.COLOR_ACCENT || '#25d366';
    },
    getStyleFull(details, calendarEvent, index) {
      const past = calendarEvent.time.start.isBefore(this.today);
      const cancelled = calendarEvent.cancelled;

      const color = this.getStyleColor(details, calendarEvent);
      const stateColor = this.getStyleColor(details, calendarEvent, past, cancelled);

      return {
        top: ((calendarEvent.row - (index || 0)) * this.eventHeight) + 'px',
        backgroundColor: stateColor,
        color: isLight(stateColor) ? '#080807' : '#fff',
        left: calendarEvent.starting ? '0px' : '-5px',
        right: calendarEvent.ending ? '0px' : '-6px',
        textDecoration: cancelled ? 'line-through' : 'inherit',
        textDecorationColor: cancelled ? color : 'inherit',
      };
    },
    getStylePlaceholderFull(details, calendarEvent, index, forDay) {
      const stateColor = this.getStyleColor(details, calendarEvent);
      const starting = calendarEvent.time.start.sameDay(forDay);
      const ending = calendarEvent.time.end.sameDay(forDay);

      return {
        top: ((calendarEvent.row - (index || 0)) * this.eventHeight) + 'px',
        color: isLight(stateColor) ? '#080807' : '#fff',
        left: starting ? '0px' : '-5px',
        right: ending ? '0px' : '-6px',
        backgroundColor: this.blend(stateColor, this.placeholderBlendAmount, this.placeholderBlendTarget),
      };
    },
    getStyleTimed(details, calendarEvent) {
      const past = calendarEvent.time.end.isBefore(this.now);
      const cancelled = calendarEvent.cancelled;
      const bounds = calendarEvent.getTimeBounds(this.dayHeight, 1, this.columnOffset);

      const stateColor = this.getStyleColor(details, calendarEvent, past, cancelled);

      return {
        top: bounds.top + 'px',
        height: bounds.height + 'px',
        left: bounds.left + '%',
        width: (100 - bounds.left) + '%',
        backgroundColor: stateColor,
        marginLeft: calendarEvent.starting ? 0 : '-5px',
        marginRight: calendarEvent.ending ? 0 : '-5px',
        textDecoration: cancelled ? 'line-through' : 'inherit',
        textDecorationColor: cancelled ? stateColor : 'inherit',
        boxShadow: bounds.left ? '-2px 2px 6px rgba(0, 0, 0, 0.3)' : 'none',
      };
    },
    getPrefix(calendarEvent, sameDay) {
      if (vuetify.framework.breakpoint.xs) {
        return '';
      }

      return sameDay.length === 1 ? sameDay[0].start.format('HH:mm') : '(' + sameDay.length + ')';
    },
  },
});

Vue.$dayspan.addLocale('nl', nlDS);
Vue.$dayspan.setLocale('nl', true);
