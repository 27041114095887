<template>
  <v-app dark class="background">
    <snackbar/>
    <alert/>
    <v-container :class="{'px-10': $breakpoint.is.smAndDown}" class="fill-height fluid padding">
      <v-content>
        <nuxt/>
      </v-content>
    </v-container>
  </v-app>
</template>

<script>
import Snackbar from '~/layouts/partials/Snackbar';
import Alert from '~/layouts/partials/Alert';

export default {
  components: {
    Snackbar,
    Alert,
  },
};
</script>

<style lang="sass" scoped>

.background
  background: #131313

@media screen and (min-width: map-get($grid-breakpoints, 'sm'))
  .background
    background: linear-gradient(to left, rgba(0,0,0,0) 38%, rgba(8,8,7,0.8) 38%), linear-gradient(180deg, rgba(34,16,0,0) 0%, #131313 100%), url("../assets/img/login-bg.jpg") no-repeat center
    background-size: cover

@media screen and (min-width: map-get($grid-breakpoints, 'md'))
  .background
    background: linear-gradient(to left, rgba(0,0,0,0) 55%, rgba(8,8,7,0.8) 55%), linear-gradient(180deg, rgba(34,16,0,0) 0%, #131313 100%), url("../assets/img/login-bg.jpg") no-repeat center
    background-size: cover

</style>
