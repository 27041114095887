export const state = () => ({
  items: [],
  layout: 'month',
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_LAYOUT(state, layout) {
    state.layout = layout;
  },
};

export const actions = {
  async getRange({state, commit}, options = {}) {
    // Clean up empty values
    Object.keys(options).forEach(key => (options[key] === null) && delete options[key]);

    // Prepare url
    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/calendar?${params}`;

    try {
      const {data} = await this.$axios.get(url);

      commit('SET_ITEMS', data.data);
    } catch (e) {
      console.error(e);
    }
  },
};
