export const state = () => ({
  subscription: {
    loaded: false,
  },
  invoices: {
    loaded: false,
  },
});

export const getters = {
  subscription(state) {
    return state.subscription;
  },
  invoices(state) {
    return state.invoices;
  },
};

export const mutations = {
  SET_SUBSCRIPTION_DATA(state, data) {
    state.subscription = data;
    state.subscription.loaded = true;
  },
  SET_INVOICES_DATA(state, data) {
    state.invoices.data = data;
    state.invoices.loaded = true;
  },
};

export const actions = {
  fetchSubscriptionData({state, commit, dispatch}) {
    if (Object.keys(state.subscription).length === 1) {
      dispatch('fetchNewSubscriptionData');
    }
  },
  async fetchNewSubscriptionData({state, commit}) {
    try {
      state.subscription.loaded = false;
      const {data: {data}} = await this.$axios.get('subscription/show');

      commit('SET_SUBSCRIPTION_DATA', data);
    } catch (e) {
      console.error(e);
    }
  },
  fetchInvoiceData({state, commit, dispatch}) {
    if (Object.keys(state.invoices).length === 1) {
      dispatch('fetchNewInvoiceData');
    }
  },
  async fetchNewInvoiceData({state, commit}) {
    try {
      state.invoices.loaded = false;
      const {data} = await this.$axios.get('company/recent/invoices');

      commit('SET_INVOICES_DATA', data);
    } catch (e) {
      console.error(e);
    }
  },
};
