<template>
  <div class="d-flex justify-center">
    <img :src="src" :alt="alt" :style="{width: imageWidth, height: imageHeight}">
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: String,
      required: false,
      default: '100px',
    },
    imageHeight: {
      type: String,
      required: false,
      default: '100px',
    },
  },
};
</script>
