<template>
  <div>
    <v-card :class="{'error-outline': error.length}">
      <v-container class="fill-height justify-center">
        <v-row align="center">
          <v-col class="px-0 text-center" cols="12">
            <input
              id="file"
              ref="file"
              type="file"
              class="file"
              :accept="acceptedFileTypes"
              @change="handleFileUpload"
            >
            <div class="white--text opacity-20" @click="openFileDialog">
              <font-awesome-icon :icon="['fal', 'plus-circle']" size="3x"/>
            </div>
            <h3 class="subtitle-1 mt-2">{{ title }}</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <p class="error-message">{{ getErrorMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Voeg bijlage toe',
    },
    error: {
      type: String,
      default: '',
    },
    acceptedFileTypes: {
      type: String,
      default: '.jpg,.jpeg,.png,.gif,.bmp,.docx,.doc,.pages,.xls,.xlsx,.ppt,.pptx,.pub,.pdf,.csv',
    },
  },
  computed: {
    getErrorMessage() {
      if (this.errors.bijlage && this.error !== '') {
        return this.errors.bijlage[0];
      } else {
        return '';
      }
    },
  },
  methods: {
    openFileDialog() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.error = '';
      const file = this.$refs.file.files[0];
      this.$emit('change:file-upload', file);
      this.$refs.file.value = '';
    },
  },
};

</script>

<style lang="sass" scoped>
.v-card
  border: 1px dashed #979797
  border-radius: 10px
  background-color: var(--v-black-lighten2)
  cursor: pointer

.container
  height: 175px

.file
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  opacity: 0
  z-index: 1000

.error-outline
  border: 1px dashed red !important

.error-message
  color: red
</style>
