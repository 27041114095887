export default ({store, route, redirect}) => {
  const redirectWithCheck = (url) => {
    if (route.path !== url) {
      return redirect(url);
    }
  };

  if (store.state.auth.loggedIn) {
    const user = store.state.auth.user;
    const isMissingInfo = !user.company || user.is_missing_info;

    if (!user.has_subscription && !user.has_access) {
      return redirectWithCheck('/registreren/betaling');
    }

    if (isMissingInfo) {
      store.commit('notify/info', 'Vul eerst je persoonsgegevens aan om door te gaan.');

      return redirectWithCheck('/account');
    }
  }
};
