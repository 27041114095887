<template>
  <v-footer v-if="actions.length || $breakpoint.is.xsOnly" :min-height="68" app fixed class="pa-0">
    <v-row v-if="actions.length" justify="end">
      <v-col class="text-right pr-2" cols="12" sm="6" lg="4">
        <v-btn
          v-for="action in actions"
          :key="action.label"
          :disabled="action.disabled"
          :type="action.event.indexOf('submit') === 0 ? 'submit' : 'button'"
          :loading="action.loading"
          :outlined="!action.color"
          primary
          width="150"
          large
          class="caption font-weight-bold mr-5"
          color="primary"
          @click="EventBus.$emit(`footer:${action.event}`)"
        >
          {{ action.label }}
        </v-btn>
      </v-col>
    </v-row>
    <menu-mobile v-if="!noMobileFooter" class="d-sm-none"/>
  </v-footer>
</template>

<script>
import {mapState} from 'vuex';
import EventBus from '~/plugins/eventbus';
import MenuMobile from '~/layouts/partials/MenuMobile';

export default {
  components: {
    MenuMobile,
  },
  props: {
    noMobileFooter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    EventBus,
  }),
  computed: mapState({actions: state => state.footer.actions}),
};
</script>
