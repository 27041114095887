<template>
  <v-btn :to="to" :class="['caption font-weight-bold', 'text--' + color]" large color="primary" @click="$emit('click')">
    {{ label }}
    <font-awesome-icon v-if="icon.length" :icon="icon" size="1x" class="ml-4"/>
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: () => ({}),
    },
    icon: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
