// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/noah-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/noah-bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/noah-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/noah-regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:300;font-style:normal}[data-v-71b349b3] .v-toolbar__extension{box-shadow:0 15px 15px 0 #080807;background-color:var(--v-black-lighten2);padding:16px 48px;margin:0 -48px}@media screen and (min-width:600px){[data-v-71b349b3] .v-toolbar__extension{padding:32px 48px}}.search-results[data-v-71b349b3]{max-height:80vh;overflow:auto}.search-results.desktop[data-v-71b349b3]{max-height:50vh;overflow:auto}.logo[data-v-71b349b3]{height:48px;width:auto}", ""]);
// Exports
module.exports = exports;
