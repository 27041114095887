<template>
  <v-app-bar
    class="toolbar elevation-1 black lighten-3 pl-sm-8 pr-sm-6"
    wrap
    app
    extension-height="auto"
  >
    <nuxt-link to="/" class="d-flex d-sm-none">
      <img :src="appLogo" :alt="appName" class="logo">
    </nuxt-link>
    <v-spacer class="d-sm-none"/>
    <v-text-field
      ref="searchField"
      v-model="searchQuery"
      hide-details
      solo
      single-line
      flat
      autofocus
      placeholder="Zoek in alle documenten..."
      class="d-none d-sm-flex"
      background-color="transparent"
      type="search"
    >
      <template v-slot:prepend-inner>
        <font-awesome-icon :icon="['far', 'search']" class="mr-2 white--text opacity-50"/>
      </template>
    </v-text-field>
    <v-btn class="d-sm-none" icon color="white" @click="searchFieldOpen = !searchFieldOpen">
      <font-awesome-icon :icon="['far', 'search']" size="lg"/>
    </v-btn>
    <v-menu v-if="user" :close-delay="300" :open-on-hover="$device.isDesktop" offset-y icon>
      <template v-slot:activator="{on}">
        <v-btn icon class="mr-n3 mr-sm-0" color="white" v-on="on">
          <svg height="50" width="50" style="position: absolute; display: none;" class="profile-hint">
            <circle
              cx="25"
              cy="25"
              r="20"
              stroke-width="3"
              fill="transparent"
              style="stroke-dasharray: 5;stroke: green;"
            />
          </svg>
          <font-awesome-icon :icon="['fal', 'user']" size="lg"/>
        </v-btn>
      </template>
      <v-list dense class="elevation-5 pa-5 black lighten-3">
        <v-list-item class="mb-4">
          <v-list-item-content>
            <v-list-item-title class="caption font-weight-bold">
              {{ user.first_name + ' ' + user.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption font-weight-bold opacity-50">{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-4"/>
        <v-list-item to="/account" nuxt>
          <v-list-item-title class="caption font-weight-bold">Mijn account</v-list-item-title>
          <v-list-item-action>
            <font-awesome-icon :icon="['fal', 'chevron-right']" class="accent--text" size="sm"/>
          </v-list-item-action>
        </v-list-item>
        <v-list-item to="/instellingen" nuxt>
          <v-list-item-title class="caption font-weight-bold">Instellingen</v-list-item-title>
          <v-list-item-action>
            <font-awesome-icon :icon="['fal', 'chevron-right']" class="accent--text" size="sm"/>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="$breakpoint.is.smAndUp" @click="resetTutorialStatus">
          <v-list-item-title class="caption font-weight-bold">Rondleiding starten</v-list-item-title>
          <v-list-item-action>
            <font-awesome-icon :icon="['fal', 'chevron-right']" class="accent--text" size="sm"/>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :href="salesUrl('/faq')" target="_blank">
          <v-list-item-title class="caption font-weight-bold">FAQ</v-list-item-title>
          <v-list-item-action>
            <font-awesome-icon :icon="['fal', 'chevron-right']" class="accent--text" size="sm"/>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title class="caption font-weight-bold">Uitloggen</v-list-item-title>
          <v-list-item-action>
            <font-awesome-icon :icon="['fal', 'sign-out-alt']" class="accent--text" size="sm"/>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
    <template v-if="searchQuery.length || searchFieldOpen" v-slot:extension>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="5">
            <v-text-field
              v-model="searchQuery"
              flat
              hide-details
              outlined
              single-line
              autofocus
              type="search"
              @keydown.esc="clearSearch"
            >
              <template v-slot:prepend-inner>
                <font-awesome-icon
                  :icon="['far', 'search']"
                  class="mr-4 white--text opacity-50 searchbar-icon"
                  size="lg"
                />
              </template>
              <template v-slot:append>
                <font-awesome-icon
                  :icon="['far', 'times']"
                  class="clearable-icon white--text opacity-50 mr-0 mt-1"
                  @click="clearSearch"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5" offset-md="1">
            <v-select
              v-model="filterType"
              :items="getFilterTypes"
              label="Selecteer type"
              outlined
              single-line
              hide-details
            >
              <template v-slot:prepend-inner>
                <font-awesome-icon
                  :icon="['fal', 'file']"
                  :color="$vuetify.theme.themes.dark.primary"
                  size="lg"
                  class="filter-icon mr-4"
                />
              </template>
              <template v-slot:append>
                <font-awesome-icon :icon="['fal', 'chevron-down']" size="lg" class="mr-0"/>
              </template>
            </v-select>
          </v-col>
          <div
            v-if="searchQuery.length > 3 && searching"
            :class="{'search-results': $breakpoint.is.xs}"
            class="text-center mt-6 flex sm12"
          >
            <v-progress-circular :width="3" indeterminate color="primary" class="mb-2"/>
            <p>Zoeken...</p>
          </div>
          <v-col
            v-else
            :class="{'search-results': $breakpoint.is.xs, 'search-results-desktop': !$breakpoint.is.xs}"
            cols="12"
            class="mt-0"
          >
            <p v-if="results.length < 1 && showSearchResult && searchQuery" class="text-center sm12">Geen resultaten</p>
            <v-row v-else no-gutters>
              <v-col
                v-for="(result, index) in results"
                :key="index"
                v-href
                :offset-sm="index % 2 === 0 ? 0 : 1"
                cols="12"
                sm="5"
                @click="clearSearch()"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card :class="{'black lighten-1' : hover}" color="transparent" flat>
                    <v-card-text>
                      <nuxt-link :to="{name: `${result.plural}-uuid`, params: {uuid: result.uuid}}" class="white--text">
                        <div v-html="result.title"/>
                      </nuxt-link>
                      <div class="primary--text">{{ result.type | ucFirst }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-app-bar>
</template>

<script>
import eventbus from '@/plugins/eventbus';
import {debounce} from '~/assets/helpers';

export default {
  data: () => ({
    results: [],
    originalResults: [],
    searchQuery: '',
    timeout: null,
    activated: false,
    filterType: '',
    searching: false,
    showSearchResult: true,
    searchFieldOpen: false,
  }),
  computed: {
    getFilterTypes() {
      const filters = [
        {
          text: 'Geen filter',
          value: 'none',
        },
        {
          text: 'Factuur',
          value: 'factuur',
        },
        {
          text: 'Offerte',
          value: 'offerte',
        },
        {
          text: 'Middel',
          value: 'middel',
        },
        {
          text: 'Bestelling',
          value: 'bestelling',
        },
      ];

      if (this.appModules.includes('relaties')) {
        filters.push({
          text: 'Relatie',
          value: 'relatie',
        });
      }

      return filters;
    },
  },
  watch: {
    searchQuery: debounce(async function (newVal) {
      // If the new value in the model has a length longer than 2 do a search query on the API and set its result on the appropriate data source.
      if (newVal.length > 2) {
        Object.assign(this, {
          searching: true,
        });
        const searchResponse = await this.$axios.get(`/search?q=${encodeURIComponent(newVal)}`);
        Object.assign(this, {
          searching: false,
          showSearchResult: true,
          results: searchResponse.data.data,
          originalResults: searchResponse.data.data,
        });
      }
    }, 400, function () {
      // Function that gets called immediately. Clear all the results before searching new ones.
      // If we don't do this the user will see their old search results before getting the new ones.
      Object.assign(this, {
        results: [],
        showSearchResult: false,
        searching: true,
      });
    }),
    filterType(newVal) {
      this.filterResults(newVal);
    },
    originalResults() {
      if (this.filterType) {
        this.filterResults(this.filterType);
      }
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('middelen/clearAll');
      await this.$store.dispatch('activities/clearAll');
      await this.$auth.logout();
    },
    clearSearch() {
      this.$nextTick(() => {
        this.searchQuery = '';
      });
    },
    filterResults(filter) {
      if (filter === 'none' || filter === '') {
        this.filterType = '';
        this.results = this.originalResults;
      } else if (filter !== '') {
        const originalTemp = Object.values(this.originalResults);
        this.results = originalTemp.filter((item) => {
          return item.type === filter;
        });
      }
    },
    async resetTutorialStatus() {
      this.$store.dispatch('profile/resetTutorialStatus');
      await this.$axios.post('introduction/reset');

      if (this.$route.path === '/') {
        eventbus.$emit('open-dashboard-introduction');
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-toolbar__extension
  box-shadow: 0 15px 15px 0 #080807
  background-color: var(--v-black-lighten2)
  padding: 16px 48px
  margin: 0 -48px

  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    padding: 32px 48px

.search-results
  max-height: 80vh
  overflow: auto

.search-results.desktop
  max-height: 50vh
  overflow: auto

.logo
  height: 48px
  width: auto
</style>
