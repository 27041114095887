<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="7" md="5" lg="4">
        <v-row justify="start">
          <v-col>
            <v-col class="mb-7 pl-0" cols="8" sm="9" md="8" lg="6">
              <a :href="salesUrl('/')" class="d-block">
                <img :src="appLogo" :alt="appName">
              </a>
            </v-col>
            <sign-in class="my-6"/>
            <nuxt-link to="/wachtwoord-vergeten" class="caption accent--text mb-1 d-block">
              Wachtwoord vergeten?
            </nuxt-link>
            <nuxt-link to="/registreren" class="accent--text caption d-block">
              <span class="caption white--text">Nog geen account?</span> Probeer nu gratis
            </nuxt-link>
          </v-col>
        </v-row>
      </v-col>
      <v-row v-show="$breakpoint.is.smAndUp" justify="end">
        <v-col class="text-right" cols="7" sm="8" md="6" lg="6" xl="5">
          <span class="display-3 text-uppercase">
            Onze software geeft inzicht in je <mark>projecten</mark> en structuur aan je <mark>werkprocessen</mark>
          </span>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import SignIn from '~/components/SignIn';

export default {
  layout: 'signin',
  middleware: 'guest',
  components: {
    SignIn,
  },
  head() {
    return {
      title: 'Inloggen',
    };
  },
};
</script>
