export const state = () => ({
  popular_layouts: {},
});

export const mutations = {
  SET_COMPANY(state, payload) {
    this.state.auth.user.company = payload;
  },

  SET_COMPANY_CUSTOM_FIELDS(state, payload) {
    this.state.auth.user.company.custom_fields = payload;
  },

  SET_COMPANY_NEXT_INVOICE_NUMBER(state, payload) {
    this.state.auth.user.company.invoice.next_nr = payload;
  },

  SET_COMPANY_NEXT_QUOTATION_NUMBER(state, payload) {
    this.state.auth.user.company.quotation.next_nr = payload;
  },

  SET_COMPANY_NEXT_ORDER_NUMBER(state, payload) {
    this.state.auth.user.company.order.next_nr = payload;
  },

  SET_COMPANY_NEXT_CALCULATION_NUMBER(state, payload) {
    this.state.auth.user.company.calculation.next_nr = payload;
  },

  SET_TUTORIAL(state, payload) {
    if (payload === 0) {
      this.state.auth.user.main_introduction = true;
    } else if (payload === 1) {
      this.state.auth.user.statement_index_introduction = true;
    } else if (payload === 2) {
      this.state.auth.user.statement_create_introduction = true;
    }
  },
  RESET_TUTORIAL() {
    this.state.auth.user.main_introduction = false;
    this.state.auth.user.statement_index_introduction = false;
    this.state.auth.user.statement_create_introduction = false;
  },
  SET_POPULAR_LAYOUTS(state, payload) {
    state.popular_layouts = payload;
  },
};

export const actions = {
  setCompany({state, commit}, payload) {
    commit('SET_COMPANY', payload);
  },
  setCompanyCustomFields({state, commit}, payload) {
    commit('SET_COMPANY_CUSTOM_FIELDS', payload);
  },
  setCompanyNextInvoiceNumber({state, commit}, payload) {
    commit('SET_COMPANY_NEXT_INVOICE_NUMBER', payload);
  },
  setCompanyNextQuotationNumber({state, commit}, payload) {
    commit('SET_COMPANY_NEXT_QUOTATION_NUMBER', payload);
  },
  setCompanyNextOrderNumber({state, commit}, payload) {
    commit('SET_COMPANY_NEXT_ORDER_NUMBER', payload);
  },
  setCompanyNextCalculationNumber({state, commit}, payload) {
    commit('SET_COMPANY_NEXT_CALCULATION_NUMBER', payload);
  },
  setTutorialStatus({state, commit}, payload) {
    commit('SET_TUTORIAL', payload);
  },
  resetTutorialStatus({commit}) {
    commit('RESET_TUTORIAL');
  },
  setPopularLayouts({state, commit}, payload) {
    commit('SET_POPULAR_LAYOUTS', payload);
  },
};

export const getters = {
  popular_base_layout(state) {
    return state.popular_layouts.base;
  },
  popular_invoice_layout(state) {
    return state.popular_layouts.invoice;
  },
  popular_quotation_layout(state) {
    return state.popular_layouts.quotation;
  },
  popular_order_layout(state) {
    return state.popular_layouts.order;
  },
  popular_calculation_layout(state) {
    return state.popular_layouts.calculation;
  },
};
