<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{zIndex: options.zIndex}" :persistent="options.persistent" @keydown.esc="cancel">
    <v-card tag="form" method="post" color="black lighten-1" @submit.prevent="agree">
      <v-toolbar :color="options.color" dark dense flat>
        <v-toolbar-title class="black--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message || options.forceBody" class="pa-7 pb-0">
        <div v-if="message" v-html="message"/>
        <text-field
          v-if="options.prompt"
          v-model="promptValue"
          :label="options.label"
          hide-details
          class="pt-2"
        />
        <div v-if="options.fields && Object.keys(options.fields).length > 0">
          <v-row :class="{'no-gutters': !options.gutters, 'pb-4': !options.hasFooter && !options.question}" justify="space-between">
            <v-col v-for="(field, i) in options.fields" :key="i" :cols="field.cols || colWidth" class="mt-4" :class="field.centered ? 'd-flex flex-column align-center' : ''">
              <component
                :is="field.type"
                v-if="field.condition ? field.condition() : true"
                v-model="fields[field.name]"
                v-bind="field"
                @action:submit="handleFileUpload"
              />
              <div v-if="field.progressHint" class="pt-3" v-html="field.progressHint"/>
            </v-col>
          </v-row>
        </div>
        <vue-signature-pad
          v-if="options.signature"
          v-show="dialog"
          ref="signature"
          width="100%"
          :options="{onEnd: saveSignature}"
          class="mt-4 signature-pad"
        />
        <v-checkbox v-if="options.checkbox" v-model="checkboxValue" required class="mt-2 mb-6" hide-details>
          <template v-slot:label>
            <v-tooltip :disabled="!options.checkbox.tooltip || options.checkbox.tooltip.length < 1" bottom>
              <template v-slot:activator="{on}">
                <div v-on="on" v-html="options.checkbox.text"/>
              </template>
              {{ options.checkbox.tooltip }}
            </v-tooltip>
          </template>
        </v-checkbox>
        <p v-if="options.footerText">{{ options.footerText }}</p>
      </v-card-text>
      <v-card-actions v-if="options.hasFooter && !options.question && !options.customButtons && !options.alert" class="mt-4 pb-5 px-7">
        <v-spacer/>
        <v-btn
          text
          outlined
          color="primary"
          @click.native="cancel"
        >
          Annuleren
        </v-btn>
        <v-btn :color="options.color" :loading="loading" depressed type="submit">{{ options.buttonText }}</v-btn>
      </v-card-actions>
      <v-card-actions v-if="options.alert" class="mt-4 pb-5 px-7">
        <v-spacer/>
        <v-btn :color="options.color" depressed @click.native="cancel">Ok</v-btn>
      </v-card-actions>
      <v-card-actions v-if="options.question" class="mt-4 pb-5 px-7">
        <v-spacer/>
        <v-btn
          text
          outlined
          color="primary"
          @click.native="agree(false)"
        >
          Nee
        </v-btn>
        <v-btn :color="options.color" :loading="loading" depressed @click.native="agree(true)">Ja</v-btn>
      </v-card-actions>
      <v-card-actions v-if="options.customButtons" class="mt-4 pb-5 px-7">
        <v-spacer/>
        <v-btn
          text
          outlined
          color="primary"
          @click.native="cancel"
        >
          Annuleren
        </v-btn>
        <v-btn
          v-for="(customButton, index) in options.customButtons"
          :key="index"
          :text="customButton.outlined || false"
          :outlined="customButton.outlined || false"
          :color="customButton.color || 'primary'"
          @click.native="agree(customButton.choice)"
        >
          {{ customButton.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueSignaturePad from 'vue-signature-pad';
import {VProgressCircular} from 'vuetify/lib';
import DialogImage from '~/components/dialogs/DialogImage';
import SingleAttachment from '~/components/forms/SingleAttachment';
import TextField from '~/components/fields/TextField';
import PasswordField from '~/components/fields/PasswordField';
import SelectField from '~/components/fields/SelectField';
import DatePicker from '~/components/fields/DatePicker';
import ImageUploadField from '~/components/fields/ImageUploadField';
import EventBus from '~/plugins/eventbus';

/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  components: {
    TextField,
    PasswordField,
    SelectField,
    DatePicker,
    ImageUploadField,
    VueSignaturePad,
    DialogImage,
    VProgressCircular,
    SingleAttachment,
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    loading: false,
    defaultOptions: {
      promptDefault: '',
      color: 'primary',
      width: 350,
      zIndex: 200,
      alert: false,
      prompt: false,
      label: '',
      buttonText: 'Ja',
      callback: null,
      checkbox: null,
      fields: [],
      fieldsInline: false,
      hasFooter: true,
      forceBody: false,
      persistent: false,
      gutters: false,
      question: false,
      footerText: null,
    },
    options: {},
    promptValue: '',
    checkboxValue: false,
    fields: {},
  }),
  computed: {
    colWidth() {
      if (this.options.fieldsInline) {
        const cols = 12 / this.options.fields.length;

        if (cols === 5 || cols > 6) {
          return 4;
        }

        return cols;
      }

      return 12;
    },
  },
  watch: {
    async dialog(val) {
      if (!val) {
        EventBus.$emit('confirm-dialog-close');
      } else if (!this.options.hasFooter) {
        // Immediately run the agree function if the dialog doesn't have a footer.
        await this.agree();
      }
    },
  },
  methods: {
    open(title, message, options = {}) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.promptValue = options.promptDefault || '';
      this.fields = {};
      this.options = Object.assign(this.options, {...this.defaultOptions, ...options});
      if (options.signature) {
        setTimeout(() => {
          const signaturePad = document.querySelector('.signature-pad');
          const canvas = document.querySelector('.signature-pad > canvas');
          if (signaturePad) {
            canvas.width = signaturePad.offsetWidth;
            canvas.height = 150;
          }
          this.$refs.signature.resizeCanvas();
        }, 100);
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async agree(result = null) {
      let canClose = true;
      if (this.options.callback) {
        this.loading = true;
        try {
          if ((this.options.fields.length || this.options.signature) && !this.options.question) {
            const callbackRes = await this.options.callback(this.fields);
            if (callbackRes !== undefined) {
              canClose = callbackRes;
            }
          } else if (result !== null) {
            const callbackRes = await this.options.callback(result);
            if (callbackRes !== undefined) {
              canClose = callbackRes;
            }
          } else {
            await this.options.callback();
          }
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
      }
      this.resolve(this.options.prompt ? this.promptValue : true);
      if (canClose) {
        this.dialog = false;
      }
    },
    cancel() {
      this.resolve(false);
      this.clearSignature();
      this.dialog = false;
      this.fields = {};
      EventBus.$emit('confirm-dialog-close');
    },
    handleFileUpload(uploadData) {
      let data = null;

      if (uploadData.image) {
        data = uploadData.image;
      } else {
        data = uploadData;
      }

      this.fields[uploadData.name] = data;
    },
    clearSignature() {
      if (this.$refs.signature) {
        this.$refs.signature.clearSignature();
      }
    },
    saveSignature() {
      const {isEmpty, data} = this.$refs.signature.saveSignature();
      this.fields.signature = isEmpty ? undefined : data;
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep canvas
  height: auto !important
  background: white
</style>
