<template>
  <v-app>
    <toolbar-akkoord/>
    <alert/>
    <snackbar/>
    <confirm-dialog ref="confirm"/>
    <v-content>
      <nuxt/>
    </v-content>
  </v-app>
</template>

<script>
import {mapState} from 'vuex';
import Snackbar from '~/layouts/partials/Snackbar';
import ToolbarAkkoord from '~/layouts/partials/ToolbarAkkoord';
import ConfirmDialog from '~/components/dialogs/ConfirmDialog';
import Alert from '~/layouts/partials/Alert';

export default {
  components: {
    Snackbar,
    ToolbarAkkoord,
    ConfirmDialog,
    Alert,
  },
  computed: mapState({actions: state => state.footer.actions}),
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>
