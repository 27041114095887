<template>
  <v-select
    v-model="inputValue"
    v-bind="$attrs"
    outlined
    class="subtitle-1 accent--text"
    menu-props="offsetY"
    color="accent"
  >
    <template slot="append">
      <font-awesome-icon :icon="['fal', 'chevron-down']" class="mr-2"/>
    </template>
  </v-select>
</template>

<script>
import inputMixin from '~/plugins/mixins/input';

export default {
  mixins: [inputMixin],
};
</script>

<style lang="sass" scoped>

::v-deep .v-input300__slot
  border-radius: $btn-border-radius

  .svg-inline--fa
    width: 0.8em
    color: var(--v-accent-base)

::v-deep .v-input__append-inner
  height: 100% !important
  align-items: center !important
  margin-top: 0 !important
</style>
