// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/noah-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/noah-bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/noah-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/noah-regular.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/img/login-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:300;font-style:normal}.background[data-v-7786dc60]{background:#131313}@media screen and (min-width:600px){.background[data-v-7786dc60]{background:linear-gradient(270deg,transparent 38%,rgba(8,8,7,.8) 0),linear-gradient(180deg,rgba(34,16,0,0),#131313),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat 50%;background-size:cover}}@media screen and (min-width:960px){.background[data-v-7786dc60]{background:linear-gradient(270deg,transparent 55%,rgba(8,8,7,.8) 0),linear-gradient(180deg,rgba(34,16,0,0),#131313),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat 50%;background-size:cover}}", ""]);
// Exports
module.exports = exports;
