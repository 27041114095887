export default ({env, route, error}) => {
  const modules = env.MODULES ? JSON.parse(env.MODULES) : [];
  const mightBeDisabled = [
    'relaties',
    'offertes',
    'calculaties',
    'bestellingen',
    'facturen',
    'middelen',
    'agenda',
  ];

  let routeName = route.name || '';

  if (routeName.includes('-')) {
    routeName = routeName.split('-')[0];
  }

  if (routeName !== 'index' && mightBeDisabled.includes(routeName) && !modules.includes(routeName)) {
    return error({statusCode: 404});
  }
};
