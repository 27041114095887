<template>
  <v-navigation-drawer
    v-if="$breakpoint.is.smAndUp"
    v-model="drawer"
    :mini-variant-width="140"
    mini-variant
    stateless
    app
    class="pa-0 black lighten-2 menu--wrapper"
    floating
    permanent
    disable-resize-watcher
  >
    <v-toolbar flat class="black lighten-3 elevation-1">
      <v-list class="pa-0">
        <v-list-item to="/" nuxt color="transparent" class="pa-0 logo">
          <img :src="appLogo" :alt="appName">
        </v-list-item>
      </v-list>
    </v-toolbar>
    <v-list class="menu">
      <template v-for="item in menu">
        <div v-if="item.show" :key="item.title" :class="`menu-item-${item.title}`">
          <v-list-item v-bind="item.route">
            <v-list-item-action class="align-center">
              <div>
                <component :is="item.component" v-if="item.component" v-bind="{style: {'--v-primary-base': $vuetify.theme.themes.dark.warning}}"/>
              </div>
              <div class="caption">
                {{ item.title }}
              </div>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';
import IconDashboard from '~/assets/img/icons/dashboard_color.svg?inline';
import IconAgenda from '~/assets/img/icons/agenda_color.svg?inline';
import IconBestellingen from '~/assets/img/icons/bestellingen_color.svg?inline';
import IconCalculaties from '~/assets/img/icons/calculaties_color.svg?inline';
import IconRelaties from '~/assets/img/icons/relaties_color.svg?inline';
import IconFacturen from '~/assets/img/icons/facturen_color.svg?inline';
import IconOffertes from '~/assets/img/icons/offertes_color.svg?inline';
import IconMiddelen from '~/assets/img/icons/middelen_color.svg?inline';

export default {
  components: {
    IconDashboard,
    IconAgenda,
    IconBestellingen,
    IconCalculaties,
    IconFacturen,
    IconOffertes,
    IconRelaties,
    IconMiddelen,
  },
  data() {
    return {
      activeBtn: 1,
      showNav: true,
      drawer: true,
    };
  },
  computed: mapState(['menu']),
};
</script>

<style lang="sass" scoped>
.menu--wrapper
  max-height: 100% !important

::v-deep .menu
  display: flex
  flex-direction: column
  height: 550px
  padding-top: 20px
  @media (min-height: 700px)
    height: 625px
  @media (min-height: 800px)
    height: 715px
  @media (min-height: 900px)
    height: 800px
    padding-top: 48px
  justify-content: space-between

  .v-list-item
    flex-direction: column
    border-left: 2px solid transparent
    height: 70px
    @media (min-height: 800px)
      height: 85px
    padding-top: 10px

    .active
      display: none

    &.v-list-item--active, &:hover
      border-left: 2px solid var(--v-primary-base)
      color: var(--v-accent-base)

      .inactive
        display: none

      .active
        display: block

.v-list-item--link:before
  background-color: rgba(216, 216, 216, 0.3)

.v-list-item--link.logo:before
  background-color: transparent !important

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):not(:hover) ::v-deep
  svg
    opacity: .4

    path, polygon, rect
      fill: #fff !important

    g#middelen > path
      stroke: #fff !important

::v-deep .theme--dark
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):not(:hover)
    color: rgba(142, 142, 144, 1) !important

  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    color: var(accent) !important

.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child)
  margin: 0 !important

.left
  padding-left: 24px !important

.right
  padding-right: 24px !important

.menu-icon
  position: relative
  height: 25px
  width: auto

</style>
