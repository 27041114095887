import Vue from 'vue';

export const state = () => ({
  actions: [],
});

export const mutations = {
  SET(state, payload) {
    state.actions = payload;
  },
  RESET(state) {
    state.actions = [];
  },
  ADD_ACTION(state, action) {
    state.actions.push(action);
  },
  CHANGE_ACTION(state, payload) {
    const i = state.actions.findIndex((a) => {
      return a.event === payload.event;
    });

    Vue.set(state.actions[i], 'loading', payload.loading);
  },
};
