<template>
  <div>
    <v-dialog
      v-if="!$breakpoint.is.xsOnly"
      ref="dialog"
      v-model="modal"
      :return-value.sync="inputValue"
      :retain-focus="false"
      persistent
      width="290px"
      @keydown.esc="modal = false"
    >
      <template v-slot:activator="{on}">
        <v-text-field
          v-model="formattedDate"
          v-mask="`##-##-####`"
          v-bind="$attrs"
          class="subtitle-1"
          :readonly="modal"
          :label="label"
          outlined
          color="accent"
          v-on="on"
        >
          <template slot="append">
            <font-awesome-icon :icon="['far', 'calendar-alt']" class="accent--text mr-2 mt-1" size="1x" v-on="on"/>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="inputValue"
        :min="$attrs['min'] || undefined"
        :max="$attrs['max'] || undefined"
        scrollable
      >
        <v-spacer/>
        <v-btn text class="font-weight-bold" color="primary" @click="modal = false">Sluiten</v-btn>
        <v-btn text class="font-weight-bold" color="primary" @click="$refs.dialog.save(inputValue)">Kies</v-btn>
      </v-date-picker>
    </v-dialog>
    <v-text-field
      v-else
      v-model="inputValue"
      v-mask="$breakpoint.is.xsOnly ? `####-##-##` : `##-##-####`"
      v-bind="$attrs"
      class="subtitle-1"
      :label="label"
      outlined
      color="accent"
      type="date"
    >
      <template slot="append">
        <font-awesome-icon :icon="['far', 'calendar-alt']" class="accent--text mr-2 mt-1" size="1x"/>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask';
import inputMixin from '~/plugins/mixins/input';

export default {
  directives: {
    mask,
  },
  mixins: [inputMixin],
  props: {
    startWithYear: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Datum',
    },
  },
  data() {
    return {
      modal: false,
      formattedDate: null,
    };
  },
  computed: {
    labelPrefix() {
      return this.formattedDate ? 'show' : '';
    },
  },
  watch: {
    modal(val) {
      if (val && this.startWithYear) {
        setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
      }
    },
    value(val) {
      if (this.$moment(val).isValid()) {
        this.formattedDate = this.$moment(val).format('DD-MM-YYYY');
      }
    },
    formattedDate(val) {
      if (this.modal) {
        return false;
      }
      const dateArray = val.split('-');
      if (dateArray.length !== 3 || dateArray[2].length !== 4) {
        return false;
      }
      const date = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
      if (this.$moment(date).isValid()) {
        this.inputValue = date;
      }
    },
    inputValue(val) {
      if (!this.modal) {
        return false;
      }

      if (this.$moment(val).isValid()) {
        let dateFormat = 'YYYY-MM-DD';

        if (this.$vuetify.lang.current === 'nl') {
          dateFormat = 'DD-MM-YYYY';
        }

        this.formattedDate = this.$moment(val).format(dateFormat);
      }
    },
  },
  mounted() {
    if (this.$moment(this.inputValue).isValid()) {
      this.formattedDate = this.$moment(this.inputValue).format('DD-MM-YYYY');
    }
  },
};
</script>

<style lang="sass" scoped>

::v-deep .v-date-picker-years li.active
  color: var(--v-primary-base) !important

</style>
