export const state = () => ({
  relation: {details: {}},
  relations: [],
  items: [],
  perPage: null,
  total: null,
  page: 1,
});

export const mutations = {
  SET_RELATION(state, payload) {
    state.relation = payload;
  },
  SET_RELATIONS(state, payload) {
    state.relations = payload;
  },
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PER_PAGE(state, limit) {
    state.perPage = limit;
  },
};

export const actions = {
  async getPage({state, commit}, options = {uuid: null, options: {}}) {
    commit('SET_ITEMS', []);
    options.options = options.options === {} ? {} : options.options;
    const uuid = options.uuid;
    options = {
      page: state.page,
      ...options.options,
    };

    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/relations${uuid !== null ? '/' + uuid : ''}?${params}`;

    const {data} = await this.$axios.get(url);

    if (data.items) {
      data.items = Object.keys(data.items).map(function (key, index) {
        return data.items[index];
      });
      commit('SET_ITEMS', data.items);
    }

    if (data.items) {
      commit('SET_RELATION', data.data);
    } else {
      commit('SET_RELATIONS', data.data);
      commit('SET_TOTAL', parseInt(data.meta.total, 10));
      commit('SET_PER_PAGE', parseInt(data.meta.per_page, 10));
    }
  },
};
