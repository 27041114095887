<template>
  <v-row justify="center" align="center" :class="{'py-12': paddingY}">
    <v-col cols="9" sm="8" md="5" lg="6" class="upload-file text-center" :class="{'pa-5': !noPadding}">
      <input id="file" :ref="`file-${randomId}`" type="file" accept="image/*" class="file" @change="handleFileUpload">
      <v-col v-show="!image && !showPreview" cols="12">
        <div class="subtitle-1 white--text mb-5">
          {{ label }}
        </div>
        <v-btn large color="primary" class="caption font-weight-bold mb-8" @click="openFileDialog">
          Selecteer een bestand
        </v-btn>
        <span class="subtitle-1 white--text opacity-50 pt-4 d-block">
          .jpg, .png, - max. 10MB
        </span>
      </v-col>
      <v-col v-show="image || showPreview" cols="12">
        <img v-if="!showPreview" :src="image" alt="Preview">
        <img v-else :src="imagePreview" alt="Preview">
        <v-btn min-width="200" large color="primary" class="caption font-weight-bold mt-6" @click="openFileDialog">
          Afbeelding vervangen
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import random from '~/plugins/mixins/random';

export default {
  mixins: [random],
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
    paddingY: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showPreview: false,
      imagePreview: '',
      newImage: {},
      randomId: null,
    };
  },
  computed: {
    ref() {
      return `file-${this.randomId}`;
    },
  },
  created() {
    this.randomId = this.generateRandomIdentifier();
  },
  methods: {
    openFileDialog() {
      this.$refs[this.ref].click();
    },
    handleFileUpload() {
      this.newImage = this.$refs[this.ref].files[0];

      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this),
        false,
      );

      if (this.newImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.newImage.name)) {
          reader.readAsDataURL(this.newImage);
        }
      }

      if (this.name.length > 0) {
        this.$emit('action:submit', {
          name: this.name,
          image: this.newImage,
        });
        return;
      }

      this.$emit('action:submit', this.newImage);
    },
  },
};
</script>

<style lang="sass" scoped>
.upload-file
  position: relative
  border: 2px dashed rgba(var(--primary-rgb), 1)
  border-radius: 10px
  box-shadow: 0 1px 8px 0 rgba(var(--primary-rgb), 0.42)
  width: 350px
  height: 350px
  max-width: 350px

  .file
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    opacity: 0

  img
    object-fit: contain
    width: 200px
    height: 200px
</style>
