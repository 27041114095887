export default ({$axios, app, store}) => {
  $axios.onError((error) => {
    if (error.response) {
      const report = (e) => {
        if (app.$bugsnag) {
          app.$bugsnag.notify(e);
        }
      };

      switch (error.response.status) {
        case 304:
          // Not modified
          break;

        case 401:
          store.commit('notify/error', error.response.data.message || 'Je bent niet meer ingelogd. Vernieuw de pagina om door te gaan.');
          break;

        case 403:
          store.commit('notify/error', error.response.data.error || 'Je hebt niet de juiste rechten om deze actie uit te voeren.');
          report(error);
          break;

        case 422:
          store.dispatch('validation/setErrors', error.response.data.errors);
          store.commit('notify/error', 'Niet alle velden zijn correct ingevuld.');
          break;

        default:
          store.commit('notify/error', 'Er is een fout opgetreden.');
          report(error);
      }
    }

    return Promise.reject(error);
  });

  $axios.onRequest(() => {
    store.dispatch('validation/clearErrors');
  });
};
