<template>
  <v-hover>
    <v-card slot-scope="{hover}" :class="{'hovercolor': hover, 'black lighten-3': !hover}" min-height="175px" class="float">
      <v-img :src="imagePreview" contain height="100px" class="mx-4" aspect-ratio="2.75"/>
      <v-card-title>
        <div>
          <div class="subtitle-1">{{ file.name }}</div>
          <div class="caption opacity-50">{{ subTitle }}</div>
          <div v-if="deletable" v-show="hover" class="error--text pr-4 hoverbutton" @click="deleteItem">
            <font-awesome-icon :icon="['fal', 'trash-alt']" size="1x"/>
          </div>
        </div>
      </v-card-title>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    file: {
      type: Object,
      default: () => ({}),
    },
    subTitle: {
      type: String,
      default: 'Bijlage',
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showPreview: false,
      imagePreview: '',
    };
  },
  created() {
    this.imagePreview = this.file.url;
  },
  methods: {
    deleteItem() {
      this.$emit('clicked:delete', this.file.id);
    },
  },
};

</script>

<style lang="sass" scoped>
.v-card
  border-radius: 10px
  cursor: pointer

.v-card__title
  padding-top: 0 !important
  position: relative

.hovercolor
  background-color: rgba(255,255,255,0.16)

.hoverbutton
  position: absolute
  bottom: 16px
  right: 0

</style>
