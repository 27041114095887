<template>
  <v-container class="fill-height">
    <v-row class="justify-center align-center text-center">
      <v-col cols="12" sm="9" md="8" lg="5" class="justify-center pa-3 pa-sm-0">
        <img :src="require('~/assets/img/illustrations/img-404.svg')" :alt="page.title" class="image d-none d-sm-flex">
        <template v-if="error.statusCode === 410 || error.statusCode === 404">
          <h1 class="headline font-weight-bold py-4">{{ page.title }}</h1>
          <!--eslint-disable-next-line-->
              <div v-html="page.tekst"/>
        </template>
        <h1 v-else :class="{'display-3': $breakpoint.is.xsOnly}" class="py-4">
          Oeps! Die pagina kunnen we niet voor je vinden.
        </h1>
        <v-col cols="10" class="d-inline-flex px-0 pt-0 pb-8">
          <p class="font-weight-bold">Misschien bestaat de pagina niet meer, of is er een typfout gemaakt bij het invoeren van de URL. Je kunt het beste even terug gaan.</p>
        </v-col>
        <accent-button label="Ga terug" color="secondary" class="px-12" to="/"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
import AccentButton from '~/components/buttons/BtnAccentLarge';

export default {
  components: {AccentButton},
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  mounted() {
    console.log(`[${this.error.statusCode || 0}] ${this.error.message}`);
    console.log(this.error);
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>
