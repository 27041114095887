import nl from 'vuetify/es5/locale/nl';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        anchor: process.env.COLOR_ACCENT || '#25d366',
        primary: process.env.COLOR_PRIMARY || '#25d366',
        secondary: '#d8d8d8',
        accent: process.env.COLOR_ACCENT || '#25d366', // vuetify uses accent as default color for datepickers, radios etc.
        success: '#25d366',
        warning: '#ff6d00',
        error: '#ff483d',
        info: '#0094aa',
        black: {
          base: '#080807',
          lighten1: '#151318',
          lighten2: '#1d1d21',
          lighten3: '#232329',
          lighten4: '#28282c',
          lighten5: '#4b4b4b',
        },
      },
    },
  },
  icons: {
    iconfont: 'faSvg',
    values: {
      calendar: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-alt'],
        },
      },
      checkboxIndeterminate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'minus-square'],
        },
      },
      checkboxOff: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'square'],
        },
      },
      checkboxOn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'check-square'],
        },
      },
      radioOff: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'square'],
        },
      },
      radioOn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'check-square'],
        },
      },
      clear: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times'],
        },
      },
      clone: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'clone'],
        },
      },
      crossCircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'times-circle'],
        },
      },
      download: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'download'],
        },
      },
      dropdown: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'caret-down'],
        },
      },
      down: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'chevron-down'],
        },
      },
      downreg: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-down'],
        },
      },
      edit: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'edit'],
        },
      },
      file: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'file'],
        },
      },
      expand: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'caret-down'],
        },
      },
      infocircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'info-circle'],
        },
      },
      linecolumns: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'line-columns'],
        },
      },
      next: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'chevron-right'],
        },
      },
      options: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fa', 'ellipsis-h'],
        },
      },
      plus: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'plus-circle'],
        },
      },
      plusreg: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'plus'],
        },
      },
      prev: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'chevron-left'],
        },
      },
      search: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'search'],
        },
      },
      sort: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'chevron-up'],
        },
      },
      signout: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'sign-out-alt'],
        },
      },
      times: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times'],
        },
      },
      trash: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'trash-alt'],
        },
      },
      user: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'user'],
        },
      },
      visibility: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'eye-slash'],
        },
      },
      visibility_off: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'eye'],
        },
      },
      solid_left: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-left'],
        },
      },
      solid_right: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-right'],
        },
      },
      options_vert: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'ellipsis-v'],
        },
      },
      comment_bubble: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'comment'],
        },
      },
    },
  },
  lang: {
    locales: {nl},
    current: 'nl',
  },
};
