export const state = () => ({
  loadedAt: null,
  total: 0,
  items: [],
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.loadedAt = +new Date();
    state.items = payload.data;
    state.total = payload.meta.total;
  },
  CLEAR_ITEMS(state) {
    state.loadedAt = null;
    state.items = [];
    state.total = 0;
  },
};

export const actions = {
  async fetchUpcomingFresh({commit}) {
    try {
      const {data} = await this.$axios.get('/activities/upcoming');

      commit('SET_ITEMS', data);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchUpcoming({state, dispatch}) {
    if (state.loaded) {
      // Already in store
      return;
    }

    await dispatch('fetchUpcomingFresh');
  },
  clearAll({commit}) {
    commit('CLEAR_ITEMS');
  },
};
