/**
 * Debounce
 * @param fn
 * @param delay
 * @param immediate
 * @returns {Function}
 */
export function debounce(fn, delay = 200, immediate = () => {
}) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    immediate.apply(that);
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function fileReaderAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}

export default {debounce, fileReaderAsync};
