<template>
  <v-app-bar class="toolbar elevation-1 black lighten-3 pl-sm-8 pr-sm-6" wrap app>
    <v-toolbar-title>
      <nuxt-link :to="salesUrl('/')" class="d-flex">
        <img :src="appLogo" :alt="appName" class="logo">
      </nuxt-link>
    </v-toolbar-title>
    <v-spacer class="d-sm-none"/>
    <div class="ml-sm-6">Welkom, {{ clientName }}</div>
  </v-app-bar>
</template>

<script>
import EventBus from '~/plugins/eventbus';

export default {
  data() {
    return {
      clientName: '...',
    };
  },
  created() {
    EventBus.$on('clientName', (name) => {
      this.clientName = name;
    });
  },
};
</script>

<style lang="sass" scoped>
.logo
  height: 48px
  width: auto
</style>
