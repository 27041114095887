export const state = () => ({
  percentages: [],
});

export const mutations = {
  setVatPercentages(state, payload) {
    state.percentages = payload.map(vat => vat.percentage).sort((a, b) => b - a);
  },
};

export const getters = {
  options(state) {
    return state.percentages.map(p => ({text: p + '%', value: p}));
  },
};
