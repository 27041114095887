<template>
  <v-snackbar v-model="active" v-bind="snackbar.props" top multi-line>
    <v-row no-gutters align="center">
      <v-col class="subtitle-1">
        {{ snackbar.message }}
      </v-col>
      <v-col v-if="snackbar.props.action" class="shrink">
        <v-btn @click.native="snackbar.props.action">{{ snackbar.props.actionText }}</v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn icon @click="active = false">
          <font-awesome-icon :icon="['fal', 'times']" fixed-width size="lg"/>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      snackbar: state => state.notify.snackbar,
    }),
    active: {
      set(isActive) {
        this.$store.commit('notify/show', isActive);
      },
      get() {
        return this.snackbar.active;
      },
    },
  },
};
</script>
