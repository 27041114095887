// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/noah-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/noah-bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/noah-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/noah-regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:300;font-style:normal}.upload-file[data-v-444a57d0]{position:relative;border:2px dashed rgba(var(--primary-rgb),1);border-radius:10px;box-shadow:0 1px 8px 0 rgba(var(--primary-rgb),.42);width:350px;height:350px;max-width:350px}.upload-file .file[data-v-444a57d0]{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;opacity:0}.upload-file img[data-v-444a57d0]{-o-object-fit:contain;object-fit:contain;width:200px;height:200px}", ""]);
// Exports
module.exports = exports;
