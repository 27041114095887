import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _88e7b91c = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _4aa059cc = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _1c4b5890 = () => interopDefault(import('../pages/bestellingen/index.vue' /* webpackChunkName: "pages/bestellingen/index" */))
const _272a97a0 = () => interopDefault(import('../pages/calculaties/index.vue' /* webpackChunkName: "pages/calculaties/index" */))
const _414ca740 = () => interopDefault(import('../pages/facturen/index.vue' /* webpackChunkName: "pages/facturen/index" */))
const _8dc2f508 = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _e2c63352 = () => interopDefault(import('../pages/instellingen.vue' /* webpackChunkName: "pages/instellingen" */))
const _94f5820c = () => interopDefault(import('../pages/middelen/index.vue' /* webpackChunkName: "pages/middelen/index" */))
const _e9d91eb4 = () => interopDefault(import('../pages/mollie-connect.vue' /* webpackChunkName: "pages/mollie-connect" */))
const _47998776 = () => interopDefault(import('../pages/notificaties.vue' /* webpackChunkName: "pages/notificaties" */))
const _3e7d7e14 = () => interopDefault(import('../pages/offertes/index.vue' /* webpackChunkName: "pages/offertes/index" */))
const _8a9c354c = () => interopDefault(import('../pages/registreren/index.vue' /* webpackChunkName: "pages/registreren/index" */))
const _bcbdc74e = () => interopDefault(import('../pages/relaties/index.vue' /* webpackChunkName: "pages/relaties/index" */))
const _3a854e94 = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _5087b2ad = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _71246e06 = () => interopDefault(import('../pages/bestellingen/create.vue' /* webpackChunkName: "pages/bestellingen/create" */))
const _3446b1c4 = () => interopDefault(import('../pages/calculaties/create.vue' /* webpackChunkName: "pages/calculaties/create" */))
const _0ffc8b7e = () => interopDefault(import('../pages/facturen/create.vue' /* webpackChunkName: "pages/facturen/create" */))
const _22d6eb04 = () => interopDefault(import('../pages/middelen/create.vue' /* webpackChunkName: "pages/middelen/create" */))
const _8e32e1ac = () => interopDefault(import('../pages/offertes/create.vue' /* webpackChunkName: "pages/offertes/create" */))
const _ace296e4 = () => interopDefault(import('../pages/registreren/betaling.vue' /* webpackChunkName: "pages/registreren/betaling" */))
const _158c4ad0 = () => interopDefault(import('../pages/registreren/gegevens.vue' /* webpackChunkName: "pages/registreren/gegevens" */))
const _8b928cf6 = () => interopDefault(import('../pages/relaties/create.vue' /* webpackChunkName: "pages/relaties/create" */))
const _8984ea7c = () => interopDefault(import('../pages/betaling/ideal/_uuid.vue' /* webpackChunkName: "pages/betaling/ideal/_uuid" */))
const _48a63b38 = () => interopDefault(import('../pages/betaling/verificatie/_url.vue' /* webpackChunkName: "pages/betaling/verificatie/_url" */))
const _27b4e4a2 = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _ad68a0ba = () => interopDefault(import('../pages/akkoord/_uuid.vue' /* webpackChunkName: "pages/akkoord/_uuid" */))
const _62f19a03 = () => interopDefault(import('../pages/bestellingen/_uuid/index.vue' /* webpackChunkName: "pages/bestellingen/_uuid/index" */))
const _36296b7c = () => interopDefault(import('../pages/betaling/_uuid.vue' /* webpackChunkName: "pages/betaling/_uuid" */))
const _e32d602a = () => interopDefault(import('../pages/calculaties/_uuid/index.vue' /* webpackChunkName: "pages/calculaties/_uuid/index" */))
const _e1aef8c4 = () => interopDefault(import('../pages/download/_uuid.vue' /* webpackChunkName: "pages/download/_uuid" */))
const _d72db0ea = () => interopDefault(import('../pages/facturen/_uuid/index.vue' /* webpackChunkName: "pages/facturen/_uuid/index" */))
const _4b049ec2 = () => interopDefault(import('../pages/middelen/_uuid.vue' /* webpackChunkName: "pages/middelen/_uuid" */))
const _31ca8f5f = () => interopDefault(import('../pages/offertes/_uuid/index.vue' /* webpackChunkName: "pages/offertes/_uuid/index" */))
const _6d64b438 = () => interopDefault(import('../pages/relaties/_uuid/index.vue' /* webpackChunkName: "pages/relaties/_uuid/index" */))
const _467030e8 = () => interopDefault(import('../pages/relaties/_uuid/edit.vue' /* webpackChunkName: "pages/relaties/_uuid/edit" */))
const _34a34ed7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _38140ea2 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _88e7b91c,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/agenda",
    component: _4aa059cc,
    pathToRegexpOptions: {"strict":true},
    name: "agenda"
  }, {
    path: "/bestellingen",
    component: _1c4b5890,
    pathToRegexpOptions: {"strict":true},
    name: "bestellingen"
  }, {
    path: "/calculaties",
    component: _272a97a0,
    pathToRegexpOptions: {"strict":true},
    name: "calculaties"
  }, {
    path: "/facturen",
    component: _414ca740,
    pathToRegexpOptions: {"strict":true},
    name: "facturen"
  }, {
    path: "/inloggen",
    component: _8dc2f508,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen"
  }, {
    path: "/instellingen",
    component: _e2c63352,
    pathToRegexpOptions: {"strict":true},
    name: "instellingen"
  }, {
    path: "/middelen",
    component: _94f5820c,
    pathToRegexpOptions: {"strict":true},
    name: "middelen"
  }, {
    path: "/mollie-connect",
    component: _e9d91eb4,
    pathToRegexpOptions: {"strict":true},
    name: "mollie-connect"
  }, {
    path: "/notificaties",
    component: _47998776,
    pathToRegexpOptions: {"strict":true},
    name: "notificaties"
  }, {
    path: "/offertes",
    component: _3e7d7e14,
    pathToRegexpOptions: {"strict":true},
    name: "offertes"
  }, {
    path: "/registreren",
    component: _8a9c354c,
    pathToRegexpOptions: {"strict":true},
    name: "registreren"
  }, {
    path: "/relaties",
    component: _bcbdc74e,
    pathToRegexpOptions: {"strict":true},
    name: "relaties"
  }, {
    path: "/styleguide",
    component: _3a854e94,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide"
  }, {
    path: "/wachtwoord-vergeten",
    component: _5087b2ad,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-vergeten"
  }, {
    path: "/bestellingen/create",
    component: _71246e06,
    pathToRegexpOptions: {"strict":true},
    name: "bestellingen-create"
  }, {
    path: "/calculaties/create",
    component: _3446b1c4,
    pathToRegexpOptions: {"strict":true},
    name: "calculaties-create"
  }, {
    path: "/facturen/create",
    component: _0ffc8b7e,
    pathToRegexpOptions: {"strict":true},
    name: "facturen-create"
  }, {
    path: "/middelen/create",
    component: _22d6eb04,
    pathToRegexpOptions: {"strict":true},
    name: "middelen-create"
  }, {
    path: "/offertes/create",
    component: _8e32e1ac,
    pathToRegexpOptions: {"strict":true},
    name: "offertes-create"
  }, {
    path: "/registreren/betaling",
    component: _ace296e4,
    pathToRegexpOptions: {"strict":true},
    name: "registreren-betaling"
  }, {
    path: "/registreren/gegevens",
    component: _158c4ad0,
    pathToRegexpOptions: {"strict":true},
    name: "registreren-gegevens"
  }, {
    path: "/relaties/create",
    component: _8b928cf6,
    pathToRegexpOptions: {"strict":true},
    name: "relaties-create"
  }, {
    path: "/betaling/ideal/:uuid?",
    component: _8984ea7c,
    pathToRegexpOptions: {"strict":true},
    name: "betaling-ideal-uuid"
  }, {
    path: "/betaling/verificatie/:url?",
    component: _48a63b38,
    pathToRegexpOptions: {"strict":true},
    name: "betaling-verificatie-url"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _27b4e4a2,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/akkoord/:uuid?",
    component: _ad68a0ba,
    pathToRegexpOptions: {"strict":true},
    name: "akkoord-uuid"
  }, {
    path: "/bestellingen/:uuid",
    component: _62f19a03,
    pathToRegexpOptions: {"strict":true},
    name: "bestellingen-uuid"
  }, {
    path: "/betaling/:uuid?",
    component: _36296b7c,
    pathToRegexpOptions: {"strict":true},
    name: "betaling-uuid"
  }, {
    path: "/calculaties/:uuid",
    component: _e32d602a,
    pathToRegexpOptions: {"strict":true},
    name: "calculaties-uuid"
  }, {
    path: "/download/:uuid?",
    component: _e1aef8c4,
    pathToRegexpOptions: {"strict":true},
    name: "download-uuid"
  }, {
    path: "/facturen/:uuid",
    component: _d72db0ea,
    pathToRegexpOptions: {"strict":true},
    name: "facturen-uuid"
  }, {
    path: "/middelen/:uuid",
    component: _4b049ec2,
    pathToRegexpOptions: {"strict":true},
    name: "middelen-uuid"
  }, {
    path: "/offertes/:uuid",
    component: _31ca8f5f,
    pathToRegexpOptions: {"strict":true},
    name: "offertes-uuid"
  }, {
    path: "/relaties/:uuid",
    component: _6d64b438,
    pathToRegexpOptions: {"strict":true},
    name: "relaties-uuid"
  }, {
    path: "/relaties/:uuid/edit",
    component: _467030e8,
    pathToRegexpOptions: {"strict":true},
    name: "relaties-uuid-edit"
  }, {
    path: "/",
    component: _34a34ed7,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/*",
    component: _38140ea2,
    pathToRegexpOptions: {"strict":true},
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
