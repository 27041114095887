import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faChevronDown as fasFaChevronDown,
  faInfoCircle,
  faCaretDown,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';

import {
  faLineColumns,
  faCheckSquare,
  faChevronRight as fasFaChevronRight,
  faChevronLeft as fasFaChevronLeft,
  faGripVertical,
  faTimes,
  faExclamation,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSearch,
  faPlus,
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faCommentAlt,
  faSpinner,
  faTimes as farTimes,
  faComment,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faPlusCircle,
  faDownload,
  faClone,
  faTrashAlt,
  faChevronDown as falFaChevronDown,
  faChevronRight,
  faChevronLeft,
  faLongArrowLeft,
  faLongArrowRight,
  faMinusSquare,
  faSquare,
  faCircle,
  faCheckCircle,
  faChevronUp,
  faCheck,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faSignOutAlt,
  faUser,
  faFile,
  faFileTimes,
  faTimes as falTimes,
  faTimesCircle,
  faMapMarkerAlt,
  faFileInvoice,
  faMoneyCheckEdit,
  faCopy,
  faHandshake,
  faSync,
} from '@fortawesome/pro-light-svg-icons';

import {facInvoice, facOrder, facQuotation} from 'assets/icons';

config.autoAddCss = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(
  faCopy,
  faHandshake,
  faMoneyCheckEdit,
  faFileInvoice,
  faPlusCircle,
  faTrashAlt,
  faDownload,
  faClone,
  faTrashAlt,
  falFaChevronDown,
  fasFaChevronDown,
  faChevronRight,
  faChevronLeft,
  faSquare,
  faCheck,
  faLongArrowLeft,
  faLongArrowRight,
  faMinusSquare,
  faChevronUp,
  faEdit,
  faSearch,
  faPlus,
  faInfoCircle,
  faTimes,
  falTimes,
  faFileTimes,
  faCaretDown,
  faEllipsisH,
  faSignOutAlt,
  faUser,
  faFile,
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faTimesCircle,
  faLineColumns,
  faCheckSquare,
  faMapMarkerAlt,
  fasFaChevronRight,
  fasFaChevronLeft,
  faGripVertical,
  faEllipsisV,
  faCircle,
  faCheckCircle,
  faCommentAlt,
  faSpinner,
  farTimes,
  faSync,
  facInvoice,
  facOrder,
  facQuotation,
  faExclamation,
  faInfo,
  faComment,
);
