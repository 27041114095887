import Vue from 'vue';
import MediumEditor from 'medium-editor';

const me = new function () {
  this.init = (element) => {
    this.me = new MediumEditor(element, {
      placeholder: false,
      toolbar: {
        buttons: ['bold', 'italic', 'anchor'],
      },
      anchor: {
        placeholderText: 'Plak of typ een link',
      },
    });
    return this.me;
  };
}();

Vue.prototype.$mediumEditor = me;
