<template>
  <v-app>
    <navigation-drawer/>
    <toolbar/>
    <browser-alert/>
    <snackbar/>
    <confirm-dialog ref="confirm"/>
    <v-content :class="{'scrollable' : actions.length}">
      <nuxt/>
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import {mapState} from 'vuex';
import NavigationDrawer from '~/layouts/partials/NavigationDrawer';
import Snackbar from '~/layouts/partials/Snackbar';
import Toolbar from '~/layouts/partials/Toolbar';
import AppFooter from '~/layouts/partials/Footer';
import ConfirmDialog from '~/components/dialogs/ConfirmDialog';
import BrowserAlert from '~/layouts/partials/Alert';

export default {
  components: {
    BrowserAlert,
    NavigationDrawer,
    Snackbar,
    Toolbar,
    AppFooter,
    ConfirmDialog,
  },
  computed: mapState({actions: state => state.footer.actions}),
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>

<style lang="sass" scoped>
/* Fix to keep focused elements in focus */
@media screen and (min-width: map-get($grid-breakpoints, 'sm'))
  .scrollable
    max-height: calc(100vh - 68px)
    overflow: auto
</style>
