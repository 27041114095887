export const state = () => ({
  notifications: [],
  total: 0,
  totalUnread: 0,
  perPage: 10,
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.notifications = payload.data;
    state.total = payload.meta.total;
    state.totalUnread = payload.total_unread;
    state.perPage = payload.meta.per_page;
  },
  CLEAR_ITEMS(state) {
    state.notifications = [];
    state.total = 0;
    state.perPage = 10;
  },
};

export const actions = {
  async getPage({commit}, options = {uuid: null, options: {}}) {
    try {
      const params = Object.entries(options.options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
      const url = '/notifications' + (params ? `?${params}` : '');
      const {data} = await this.$axios.get(url);

      commit('SET_ITEMS', data);
    } catch (e) {
      if (e.response && e.response.status !== 304) {
        console.error(e);
      }
    }
  },
  clearAll({commit}) {
    commit('CLEAR_ITEMS');
  },
  async markUnread({commit}, id) {
    const {data} = await this.$axios.post(`/notifications/${id}`);

    commit('SET_ITEMS', data);
  },
};
