<template>
  <v-text-field
    v-model="inputValue"
    v-bind="$attrs"
    class="subtitle-1"
    type="password"
    outlined
    color="accent"
    v-on="$listeners"
    @blur="blurField"
  />
</template>

<script>
import inputMixin from '~/plugins/mixins/input';

export default {
  mixins: [inputMixin],
};
</script>

<style lang="sass" scoped>

::v-deep .v-input__slot
  svg
    width: 0.8em
    color: var(--v-primary-base)

</style>
