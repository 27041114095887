export default {
  props: {
    value: {
      type: [Object, Array, String, Number],
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputValue = val;
    },
  },
  methods: {
    blurField() {
      this.$store.dispatch('validation/clearErrors');
    },
  },
};
