export const state = () => ({
  items: [],
  page: 1,
  perPage: null,
  total: null,
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PER_PAGE(state, limit) {
    state.perPage = limit;
  },
};

export const actions = {
  async getPage({state, commit}, options = {}) {
    options = {
      page: state.page,
      ...options,
    };

    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/quotations?${params}`;

    try {
      const {data} = await this.$axios.get(url);

      commit('SET_ITEMS', data.data);
      commit('SET_TOTAL', parseInt(data.meta.total, 10));
      commit('SET_PER_PAGE', parseInt(data.meta.per_page, 10));
    } catch (e) {
      console.error(e);
    }
  },
};
