export const state = () => ({
  loaded: false,
  items: [],
  page: 1,
  perPage: null,
  total: null,
});

export const getters = {
  middel(state) {
    return uuid => state.items.find(item => item.uuid === uuid);
  },
  middelenForSupplier(state) {
    return uuid => state.items.filter(item => item.supplier_uuid === uuid);
  },
};

export const mutations = {
  SET_ITEMS(state, payload) {
    state.loaded = true;
    state.items = payload;
  },
  CLEAR_ITEMS(state) {
    state.loaded = false;
    state.items = [];
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PER_PAGE(state, limit) {
    state.perPage = limit;
  },
};

export const actions = {
  async fetchAllFresh({commit}) {
    try {
      const {data} = await this.$axios.get('/middelen');

      commit('SET_ITEMS', data.data);
      commit('SET_TOTAL', parseInt(data.meta.total, 10));
      commit('SET_PER_PAGE', parseInt(data.meta.per_page, 10));
    } catch (e) {
      console.error(e);
    }
  },
  async getPage({state, commit}, options) {
    options = {
      page: state.page,
      ...options,
    };

    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/middelen?${params}`;

    try {
      const {data} = await this.$axios.get(url);

      commit('SET_ITEMS', data.data);
      commit('SET_TOTAL', parseInt(data.meta.total, 10));
      commit('SET_PER_PAGE', parseInt(data.meta.per_page, 10));
    } catch (e) {
      console.error(e);
    }
  },
  async fetchAll({state, dispatch}) {
    if (state.loaded) {
      // Already in store
      return;
    }

    await dispatch('fetchAllFresh');
  },
  clearAll({commit}) {
    commit('CLEAR_ITEMS');
  },
};
