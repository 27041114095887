export const state = () => ({
  release: process.env.RELEASE,
  settings: {
    trial_period: 30,
    contact_email: null,
  },
  page: {},
  menu: [
    {
      title: 'Dashboard',
      route: {to: '/', nuxt: true},
      icon: 'dashboard_white.svg',
      iconActive: 'dashboard_color.svg',
      component: 'IconDashboard',
      show: true,
    },
    {
      title: 'Relaties',
      route: {to: '/relaties', nuxt: true},
      icon: 'relaties_white.svg',
      iconActive: 'relaties_color.svg',
      component: 'IconRelaties',
      show: includes('relaties'),
    },
    {
      title: 'Calculaties',
      route: {to: '/calculaties', nuxt: true},
      icon: 'calculaties_white.svg',
      iconActive: 'calculaties_color.svg',
      component: 'IconCalculaties',
      show: includes('calculaties'),
    },
    {
      title: 'Offertes',
      route: {to: '/offertes', nuxt: true},
      icon: 'offertes_white.svg',
      iconActive: 'offertes_color.svg',
      component: 'IconOffertes',
      show: includes('offertes'),
    },
    {
      title: 'Bestellingen',
      route: {to: '/bestellingen', nuxt: true},
      icon: 'bestellingen_white.svg',
      iconActive: 'bestellingen_color.svg',
      component: 'IconBestellingen',
      show: includes('bestellingen'),
    },
    {
      title: 'Facturen',
      route: {to: '/facturen', nuxt: true},
      icon: 'facturen_white.svg',
      iconActive: 'facturen_color.svg',
      component: 'IconFacturen',
      show: includes('facturen'),
    },
    {
      title: 'Agenda',
      route: {to: '/agenda', nuxt: true},
      icon: 'agenda_white.svg',
      iconActive: 'agenda_color.svg',
      component: 'IconAgenda',
      show: includes('agenda'),
    },
    {
      title: 'Middelen',
      route: {to: '/middelen', nuxt: true},
      icon: 'middelen_white.svg',
      iconActive: 'middelen_color.svg',
      component: 'IconMiddelen',
      show: includes('middelen'),
    },
  ],
  previous_route: null,
});

function includes(route) {
  const modules = process.env.MODULES;
  // const modules = JSON.stringify(['relaties', 'offertes', 'calculaties', 'bestellingen', 'facturen', 'middelen', 'agenda']);
  return modules ? JSON.parse(modules).includes(route) : true;
}

export const actions = {
  nuxtClientInit({state, commit}, {app}) {
    setTimeout(() => {
      Promise.all([
        this.$axios.get('contact'),
        this.$axios.get('vat-percentages'),
      ]).then((responses) => {
        const {data: emailData} = responses[0];
        const {data: vatData} = responses[1];

        commit('SET_CONTACT_EMAIL', emailData);
        commit('vat/setVatPercentages', vatData.data);
      });
    }, 5);

    const gtmInterval = setInterval(() => {
      if (app.$gtm) {
        if (state.auth.user && state.auth.user.uuid) {
          app.$gtm.push({event: 'userId', userId: state.auth.user.uuid});
        }

        clearInterval(gtmInterval);
      }
    }, 50);

    setInterval(async () => {
      const {data} = await this.$axios.get('version');

      if (state.release && data && state.release !== data) {
        this.$bugsnag.leaveBreadcrumb('New version: `' + JSON.stringify(data) + '`');

        commit('notify/show', {
          message: 'Er is een nieuwe versie beschikbaar. Vernieuw de pagina om deze te activeren.',
          props: {
            timeout: 0,
            action: () => window.location.reload(),
            actionText: 'Vernieuwen',
          },
        });
      }
    }, 60000 * 15); // every 15 minutes
  },
  async getPage({commit}, slug = '') {
    let page;

    if (slug === 'login') {
      page = {
        title: 'Inloggen',
      };
    } else if (slug === '404') {
      page = {
        title: 'Pagina niet gevonden',
      };
    } else {
      const response = await this.$axios.get(`/page?segments=${slug}`);
      page = response.data.data;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_CONTACT_EMAIL(state, payload) {
    state.settings.contact_email = payload;
  },
  SET_PREVIOUS_ROUTE(state, route) {
    state.previous_route = route;
  },
};
